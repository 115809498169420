<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="600px"
      height="600px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span> Components select </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-text-field
              autocomplete="off"
              hide-details
              single-line
              solo-inverted
              clearable
              dense
              flat
              :key="kl"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              class="pr-6"
            ></v-text-field>
          </v-row>
          <br />
          <div class="scroll" style="height: 300px">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-treeview
                  ref="tree"
                  :key="tkey"
                  v-model="selectedItems"
                  selectable
                  dense
                  :items="components_view"
                  return-object
                ></v-treeview>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" @click.stop="save"> Save </v-btn>
          <v-btn color="blue darken-1" @click.stop="close"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import CREATE_MULTIPLE_COMPONENT from "../graphql/Well/CREATE_Mutiple_Component.gql";
//import UPDATE_COMPONENT from "../graphql/Well/UPDATE_COMPONENT.gql";

export default {
  components: {},
  props: {
    list: Array,
    components_well: Array,
    welltubulars: Array,
    showform: Boolean,
    item: Object,
    well_id: Number,
  },
  data() {
    return {
      isDocClose: true,
      kl: 1000,
      tkey:0,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      editedItem: {},
      depth_max: 0,
      selectedItems: [],
      old_items: [],
      new_items: [],
      deleted_items: [],
      search: "",
    };
  },
  watch: {
    search: {
      handler() {
        this.components_well.filter((f) => f.label.includes(this.search));
        // this.kl++;
      },
    },
  },
  mounted() {
    this.selectedItems = [];
    this.old_items = this.components_well.filter((i) =>
      this.list.map((j) => j.component_id).includes(i.id)
    );
    this.tkey++;
  },
  created() {},
  computed: {
    components_view() {
      let nb = 0;
      if (this.welltubulars)
        nb = Math.max(...this.welltubulars.map((item) => item.csg_order));
      let cmps = this.components_well.filter(
        (i) =>
          !this.list.map((j) => j.component_id).includes(i.id) &&
          i.label.toUpperCase().includes(this.search.toUpperCase()) &&
          (i.csg_order < nb || i.csg_order == null || !i.csg_order)
      );
      let childs = cmps.filter((i) => i.compartment != null);
      childs.forEach((i) => {
        let k2 = this.components_well.findIndex((j) => j.code == i.compartment);
        let k = cmps.findIndex((y) => y.id == this.components_well[k2].id);
        if (k < 0) {
          cmps.push(this.components_well[k2]);
        }
      });
      ////cas des parents
      let v = [];
      cmps
        .sort((a, b) => a.id - b.id)
        .forEach((x) => {
          if (x.compartment == null) {
            let c = cmps.filter((i) => i.compartment == x.code);
            let ch = [];
            c.forEach((y) =>
              ch.push({
                id: y.id,
                name: y.label,
                label: y.label,
                code: y.code,
                component_id: y.component_id,
                compartment: y.compartment,
                group_id: y.group_id,
                csg_order: y.csg_order,
                orientation: y.orientation,
              })
            );
            v.push({
              id: x.id,
              name: x.label,
              code: x.code,
              children: ch,
              label: x.label,
              component_id: x.component_id,
              compartment: x.compartment,
              group_id: x.group_id,
              csg_order: x.csg_order,
              orientation: x.orientation,
            });
          }
        });

      return v;
    },
  },
  methods: {
    get_selected() {
      let _selectedNodes = [];

      this.selectedItems.forEach((x) => {
        _selectedNodes.push(x);
        if (x.compartment != null) {
          let k = this.components_well.findIndex(
            (cw) => cw.code == x.compartment
          );
          if (k >= 0) {
            if (
              this.old_items.findIndex(
                (o) => o.id == this.components_well[k].id
              ) < 0
            ) {
              if (
                _selectedNodes.findIndex(
                  (o) => o.id == this.components_well[k].id
                ) < 0
              )
                _selectedNodes.push(this.components_well[k]);
            }
          }
        }
      });
      return _selectedNodes;
    },
    close() {
      this.$emit("close");
    },
    async save() {
      let vn = [];
      let vd = [];
      let v = this.get_selected();
      v.forEach((c) => {
        vn.push({ well_id: this.item.well_id, component_id: c.id, id: c.id });
        this.old_items.push(c);
        //cas des parents
      });
      this.$apollo
        .query({
          query: CREATE_MULTIPLE_COMPONENT,
          variables: {
            news: vn,
            dels: vd,
          },
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          console.log(data);
          this.$emit("close", this.old_items);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
div.scroll {
  height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: 10px;
}
</style>
